import { InfrastructureEnv } from '../../enums/enums';
import { LOCALHOST_NETWORK_NAME } from './configs';

export const GATEWAY_NAME = 'PaymentGateway';
export const GATEWAY_NATIVE_PAYMENT_FUNCTION_NAME = 'payETH';
export const GATEWAY_TOKEN_PAYMENT_FUNCTION_NAME = 'pay';
export const GATEWAY_VERSION_ZERO_THREE = 'v0.3';
export const GATEWAY_VERSION_ZERO_FOUR = 'v0.4';

export const getVersionForNetwork = (network: string, version: string) => {
  return `${network}-${version}`;
};

export type GatewayAddress = {
  [key in InfrastructureEnv]: string;
};

// Addresses
export const LOCALHOST_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
  [InfrastructureEnv.DEVELOPMENT]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
  [InfrastructureEnv.STAGING]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
  [InfrastructureEnv.PRODUCTION]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.SANDBOX]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
};
export const ETH_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.STAGING]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.PRODUCTION]: '0x0b35D20C9B45f1312f408768BEE75150b2B8A80e',
  [InfrastructureEnv.SANDBOX]: '0x0000000000000000000000000000000000000000',
};
export const POLYGON_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
  [InfrastructureEnv.STAGING]: '0xED13f654898217cf060dcA92dD5A24DCd3fB4Df0',
  [InfrastructureEnv.PRODUCTION]: '0x31C66B892f96dB841cCdb4537AAF36f786934A91',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};
export const GNOSIS_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xc3bcf5ce1df105a96cafa984b3164489f05760cc',
  [InfrastructureEnv.STAGING]: '0x31C66B892f96dB841cCdb4537AAF36f786934A91',
  [InfrastructureEnv.PRODUCTION]: '0x254C5F6A194028fa824629A8bb570093437FBAB4',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};
export const OPTIMISM_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
  [InfrastructureEnv.STAGING]: '0x35A9D8171A63c4fB6bBC10fC8Fe2B3B3eDBdAA55',
  [InfrastructureEnv.PRODUCTION]: '0xD6373d46aE2f1459d0b5db63E2D2474606775f90',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};
export const ARBITRUM_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
  [InfrastructureEnv.STAGING]: '0x31C66B892f96dB841cCdb4537AAF36f786934A91',
  [InfrastructureEnv.PRODUCTION]: '0x35A9D8171A63c4fB6bBC10fC8Fe2B3B3eDBdAA55',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};
export const BSC_MAINNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
  [InfrastructureEnv.STAGING]: '0x0b35D20C9B45f1312f408768BEE75150b2B8A80e',
  [InfrastructureEnv.PRODUCTION]: '0x254C5F6A194028fa824629A8bb570093437FBAB4',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};

export const SEPOLIA_TESTNET_GATEWAY_ADDRESS: GatewayAddress = {
  [InfrastructureEnv.TEST]: '0x0000000000000000000000000000000000000000',
  [InfrastructureEnv.DEVELOPMENT]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
  [InfrastructureEnv.STAGING]: '0x83d54Dba1f283F4c7ea37ed0547250EB62DD5923',
  [InfrastructureEnv.PRODUCTION]: '0x0b35D20C9B45f1312f408768BEE75150b2B8A80e',
  [InfrastructureEnv.SANDBOX]: '0xC3bCf5Ce1dF105a96cafa984B3164489f05760Cc',
};

// Versions
// export const LOCALHOST_GATEWAY_VERSION = getVersionForNetwork(
//   LOCALHOST_NETWORK_NAME,
//   GATEWAY_VERSION_ZERO_FOUR,
// );

export const ETH_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const POLYGON_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const GNOSIS_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const OPTIMISM_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const ARBITRUM_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const BSC_MAINNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_FOUR,
};

export const SEPOLIA_TESTNET_GATEWAY_VERISONS = {
  [InfrastructureEnv.DEVELOPMENT]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.STAGING]: GATEWAY_VERSION_ZERO_FOUR,
  [InfrastructureEnv.PRODUCTION]: GATEWAY_VERSION_ZERO_THREE,
};
