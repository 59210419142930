import { ethers } from 'ethers';
import { Network } from '../../enums/enums';

export const PRIMARY_RPC_MAX_RETRIES = 3;

///////////////// Localhost (Anvil) /////////////////

export const LOCALHOST_NETWORK_NAME = Network.LOCALHOST;
export const LOCALHOST_CHAIN_ID = 31337;
export const LOCALHOST_BLOCK_FINALITY = 4;
export const LOCALHOST_PUBLIC_RPC_URL = 'http://localhost:8545';
export const LOCALHOST_PUBLIC_PROVIDER =
  new ethers.providers.StaticJsonRpcProvider(LOCALHOST_PUBLIC_RPC_URL, 31337);

export const LOCALHOST_LAST_HANDLED_BLOCK_CACHE_KEY =
  'localhostLastHandledBlock';
export const LOCALHOST_FINALIZED_BLOCK_CACHE_KEY = 'localhostFinalizedBlock';
export const LOCALHOST_CACHE_UPDATE_TIME_CACHE_KEY = 'localhostCacheUpdateTime';
export const LOCALHOST_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const LOCALHOST_BLOCK_PAGINATION_SIZE = 1000;

/////////////////////////////////////////////////
//////////////////// MAINNETS ///////////////////
/////////////////////////////////////////////////

///////////////// ETH Mainnet //////////////////
export const ETH_MAINNET_NETWORK_NAME = Network.ETH_MAINNET;
export const ETH_MAINNET_CHAIN_ID = 1;
export const ETH_MAINNET_BLOCK_FINALITY = 4;

export const ETH_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'ethMainnetLastHandledBlock';
export const ETH_MAINNET_FINALIZED_BLOCK_CACHE_KEY = 'ethMainnetFinalizedBlock';
export const ETH_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'ethMainnetCacheUpdateTime';

export const ETH_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const ETH_MAINNET_BLOCK_PAGINATION_SIZE = 1000;

///////////////// Polygon Mainnet //////////////////
export const POLYGON_MAINNET_NETWORK_NAME = Network.POLYGON_MAINNET;
export const POLYGON_MAINNET_CHAIN_ID = 137;
export const POLYGON_MAINNET_BLOCK_FINALITY = 30;

export const POLYGON_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'polygonMainnetLastHandledBlock';
export const POLYGON_MAINNET_FINALIZED_BLOCK_CACHE_KEY =
  'polygonMainnetFinalizedBlock';
export const POLYGON_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'polygonMainnetCacheUpdateTime';

export const POLYGON_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const POLYGON_MAINNET_BLOCK_PAGINATION_SIZE = 1000;

///////////////// Gnosis Mainnet //////////////////

export const GNOSIS_MAINNET_NETWORK_NAME = Network.GNOSIS_MAINNET;
export const GNOSIS_MAINNET_CHAIN_ID = 100;
export const GNOSIS_MAINNET_BLOCK_FINALITY = 8;

export const GNOSIS_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'gnosisMainnetLastHandledBlock';
export const GNOSIS_MAINNET_FINALIZED_BLOCK_CACHE_KEY =
  'gnosisMainnetFinalizedBlock';
export const GNOSIS_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'gnosisMainnetCacheUpdateTime';

export const GNOSIS_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const GNOSIS_MAINNET_BLOCK_PAGINATION_SIZE = 1000;

///////////////// Optimism Mainnet //////////////////

export const OPTIMISM_MAINNET_NETWORK_NAME = Network.OPTIMISM_MAINNET;
export const OPTIMISM_MAINNET_CHAIN_ID = 10;
export const OPTIMISM_MAINNET_BLOCK_FINALITY = 90;

export const OPTIMISM_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'optimismMainnetLastHandledBlock';
export const OPTIMISM_MAINNET_FINALIZED_BLOCK_CACHE_KEY =
  'optimismMainnetFinalizedBlock';
export const OPTIMISM_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'optimismMainnetCacheUpdateTime';

export const OPTIMISM_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const OPTIMISM_MAINNET_BLOCK_PAGINATION_SIZE = 1000;

///////////////// Arbitrum Mainnet //////////////////

export const ARBITRUM_MAINNET_NETWORK_NAME = Network.ARBITRUM_MAINNET;
export const ARBITRUM_MAINNET_CHAIN_ID = 42161;
export const ARBITRUM_MAINNET_BLOCK_FINALITY = 4000;

export const ARBITRUM_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'arbitrumMainnetLastHandledBlock';
export const ARBITRUM_MAINNET_FINALIZED_BLOCK_CACHE_KEY =
  'arbitrumMainnetFinalizedBlock';
export const ARBITRUM_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'arbitrumMainnetCacheUpdateTime';

export const ARBITRUM_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const ARBITRUM_MAINNET_BLOCK_PAGINATION_SIZE = 3000;

///////////////// BSC Mainnet //////////////////

export const BSC_MAINNET_NETWORK_NAME = Network.BSC_MAINNET;
export const BSC_MAINNET_CHAIN_ID = 56;
export const BSC_MAINNET_BLOCK_FINALITY = 4;

export const BSC_MAINNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'bscMainnetLastHandledBlock';
export const BSC_MAINNET_FINALIZED_BLOCK_CACHE_KEY = 'bscMainnetFinalizedBlock';
export const BSC_MAINNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'bscMainnetCacheUpdateTime';

export const BSC_MAINNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const BSC_MAINNET_BLOCK_PAGINATION_SIZE = 1000;

/////////////////////////////////////////////////
//////////////////// TESTNETS ///////////////////
/////////////////////////////////////////////////

///////////////// Eth Sepolia Testnet /////////////////

export const SEPOLIA_TESTNET_NETWORK_NAME = Network.SEPOLIA_TESTNET;
export const SEPOLIA_TESTNET_CHAIN_ID = 11155111;
export const SEPOLIA_TESTNET_BLOCK_FINALITY = 4;

export const SEPOLIA_TESTNET_LAST_HANDLED_BLOCK_CACHE_KEY =
  'sepoliaTestnetLastHandledBlock';
export const SEPOLIA_TESTNET_FINALIZED_BLOCK_CACHE_KEY =
  'sepoliaTestnetFinalizedBlock';
export const SEPOLIA_TESTNET_CACHE_UPDATE_TIME_CACHE_KEY =
  'sepoliaTestnetCacheUpdateTime';

export const SEPOLIA_TESTNET_MAX_RETRIES = PRIMARY_RPC_MAX_RETRIES;
export const SEPOLIA_TESTNET_BLOCK_PAGINATION_SIZE = 1000;
